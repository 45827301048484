.our-suggestions {
    margin: 75px auto 130px;
    display: flex;
    justify-content: space-between;
    width: 95%;
    flex-wrap: wrap;
    @include breakpoint-down(md){
        margin: 15px auto 0;
    }

    &__item-wrap {
        width: 278px;
        display: block;
        @include breakpoint-down(md){
            width: 100%;
            margin-bottom: 25px;
        }
    }

    &__item {
        min-height: 316px;

        .img {
            min-height: 242px;
            margin-bottom: 18px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            img {
                position: relative;
                max-width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .title {
            color: $primarySecond;
            text-align: center;
            position: relative;
            padding-top: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $primarySecond;
                width: 125px;
                margin: 0 auto;
            }
        }
    }
}
