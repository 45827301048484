.course {
    &-item-wrap {
        padding: 8px 6px;

        .modal-dialog {
            max-width: 911px;
        }
    }

    &__items {
        margin: -8px -6px;
    }

    &__item {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        min-height: 395px;
        padding: 14px 10px 28px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .img {
            height: 118px;
            background-color: #f5f5f5;
            position: relative;
            margin-bottom: 10px;

            &::after {
                content: '';
                width: 64px;
                height: 60px;
                position: absolute;
                background-image: url("/images/non-image.svg");
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 2;
            }
        }

        .title {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.0275em;
            color: #002554;
            min-height: 70px;
            border-top: 1px solid #002554;
            padding-top: 14px;
        }


        .price {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.87);
        }

        .date {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 143%;
            display: flex;
            align-items: center;
            letter-spacing: 0.018em;
            color: #787885;
            margin-bottom: 28px;
        }

        .time {
            font-weight: bold;
            font-size: 14px;
            line-height: 143%;
            display: flex;
            align-items: center;
            letter-spacing: 0.018em;
            color: #FFFF;
            background-color: $primarySecond;
            width: 98px;
            height: 32px;
            padding-left: 15px;
        }

        .price-time-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2px;
        }

        .price-time-date-wrap{
            height: 80px;
        }

        .more-details {
            background-color: $primaryFirst;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0.018em;
            height: 32px;
            width: 85%;
            margin: 20px auto 0;
            display: flex;
            align-items: center;
            color: #FFF;
            justify-content: center;
            border-radius: 5px;
        }
    }
}

.show-more {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: #002554;
    position: relative;
    margin-top: 38px;
    display: inline-block;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: #002554;
        height: 1px;
    }
}

.upcoming-courses {
    margin: 0 -20px 130px;
    @include breakpoint-down(md) {
        margin: 0 -20px 30px;
    }

    .swiper-container {
        padding: 18px 0 0 20px;
        margin-bottom: 40px;
        @include breakpoint-down(md) {
            margin-bottom: 0;
        }
    }

    .swiper-slide {
        width: 260px;
        min-height: 445px;
    }

    .arrow-wrap {
        margin-top: 46px;
        @include breakpoint-down(md) {
            margin-top: 20px;
            justify-content: center !important;
        }
    }

    .h2-wrap {
        margin-bottom: 60px;
        @include breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
}


.modal-body {
    .course-item-info__top {
        display: flex;
        margin-bottom: 11px;
        @include breakpoint-down(md) {
            display: block;
        }

        .left {
            width: 525px;
            height: 310px;
            @include breakpoint-down(md) {
                width: 100%;
                margin-bottom: 10px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .right {
            padding-left: 17px;
            width: calc(100% - 525px);
            @include breakpoint-down(md) {
                width: 100%;
                padding-left: 0;
            }

            .title {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.0275em;
                color: #19191D;
                margin-bottom: 11px;
            }

            .price-date-wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                border-bottom: 2px solid #787885;
                padding-bottom: 10px;

                .price {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 150%;
                    color: rgba(0, 0, 0, 0.87);
                }

                .date {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 143%;
                    letter-spacing: 0.018em;
                    color: #787885;
                }

                .time {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 143%;
                    letter-spacing: 0.018em;
                    color: #FFFFFF;
                    padding: 4px 15px;
                    background: #D50032;
                }
            }

            .about-course {
                ul {
                    padding: 15px 0 0 0;
                    margin: 0;
                    list-style: none;

                    li {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 143%;
                        letter-spacing: 0.018em;
                        color: #787885;
                        margin-bottom: 17px;
                        display: flex;
                        align-items: flex-start;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .item-icon {
                            margin-right: 10px;
                        }

                        .blue {
                            color: #002554;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;

        &__wrap {
            color: #002554;

            .title {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 150%;
                margin-bottom: 10px;
            }

            .important-info {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 143%;
                width: 430px;
                margin: 0 auto;
                text-align: center;
                @include breakpoint-down(md) {
                    width: 100%;
                }

                &__title {
                    color: #787885;
                    letter-spacing: 0.018em;
                }

                &__links {
                    a {
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 1px;
                            background-color: #002554;
                        }
                    }
                }
            }
        }
    }
}


.pay-modal {

    .preloader{
        @include stretch();
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        z-index: 4;
        z-index: 4;
        z-index: 4;
    }

    .modal-dialog {
        max-width: 460px;

        input {
            width: 100%;
        }

        .btn__primary{
            display: block;
            margin: 0 auto;
        }
    }
}
