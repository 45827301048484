.img-404 {
    width: 900px;
    margin: 0 auto;

    svg {
        max-width: 100%;
        max-height: 100%;
    }
}

.page-404{
    position: relative;
}

.error-text{
    text-align: center;
    font-size: 30px;
    color: #002554;
    position: absolute;
    left: 0;
    right: 0;
    top: 230px;
}

.page-404__top-block {
    background-color: #002554;
    height: 120px;
}

.success{
    width: 650px;
    height: 360px;
    position: fixed;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrap-inside{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .img{
        margin-bottom: 25px;
        width: 120px;
        height: 120px;

        svg{
            max-width: 100%;
            max-height: 100%;
        }
    }

    .title{
        width: 230px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #002554;
    }
}
