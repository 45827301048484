html,
body,
.wrapper {
	height: 100%;
}

body {
	color: $text-color;
	max-width: 100%;
}

.wrapper-in { // wrapper
	position: relative;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.content {
	overflow: hidden;
	flex: 1;
}
