.footer {
    min-height: 204px;
    padding: 21px 0 40px;
    background-color: $primaryFirst;
    position: relative;
    overflow-x: hidden;
    @include breakpoint-down(md){
        padding-bottom: 20px;
    }


    &__info-desc {
        &:not(:last-of-type) {
            margin-bottom: 21px;
        }

        a {
            &:not(:last-child) {
                margin-right: 10px;
            }

            &[href*="mailto:"] {
                text-decoration: underline;
            }

        }
    }

    &__inside {
        position: relative;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        @include breakpoint-down(md){
            flex-direction: column;
        }

        .footer__nav-wrap {
            width: 860px;
            @include breakpoint-down(md){
                display: none;
            }
        }
    }

    &__info {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #FFF;
        margin-right: 20px;
    }
}

.footer__bottom{
    margin-top: 50px;
    display: flex;
    align-items: flex-end;
    @include breakpoint-down(md){
        flex-direction: column-reverse;
        margin-top: 20px;
        align-items: initial;
    }
}

.footer-logo {
    width: 230px;
    @include breakpoint-down(md){
        width: 100%;
    }

    img{
        max-width: 100%;
        max-height: 100%;
    }

    &__wrap{
        display: flex;
        align-items: center;
        @include breakpoint-down(md){
            display: block;
            margin-bottom: 25px;
        }
    }

    &1 {
        margin-right: 50px;
        @include breakpoint-down(md){
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &2 {

    }
}

.social-network {
    margin-right: 100px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    li:not(:last-child) {
        margin-right: 15px;
    }

    &__item {
        display: block;
        width: 33px;
        height: 33px;

        svg {
            height: 100%;
            width: 100%;
        }
    }
}

