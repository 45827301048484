.contract-offer-page {
    padding-bottom: 56px;
    &__top-block {
        min-height: 124px;
        margin-bottom: 89px;
        background-color: #002554;
        @include breakpoint-down(md){
            min-height: 80px;
            margin-bottom: 30px;
        }
    }

    h2.first-primary-color {
        position: relative;
        display: inline-block;
        margin-bottom: 46px;

        &::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: -18px;
            right: -60px;
            height: 2px;
            background-color: #002554;
            @include breakpoint-down(md){
                left: 0;
                right: 0;
            }
        }
    }

    .desc{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #002554;
        margin-bottom: 26px;
    }

    h3{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #002554;
        margin-bottom: 22px;
    }

    .contract-offer-info{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #002554;
        margin-bottom: 26px;
    }
}
