.enterprise-solutions-page {
    @include breakpoint-down(md) {
        .text-center {
            text-align: left !important;
            h2{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    &__top-block {
        min-height: 323px;
        background: #002554;
        padding-top: 140px;
        color: #fff;

        @include breakpoint-down(md) {
            padding-top: 80px;
            min-height: auto;
            padding-bottom: 30px;
        }
    }

    .enterprise-solutions__img-info-wrap {
        display: flex;
        margin-bottom: 103px;
        @include breakpoint-down(md) {
            flex-direction: column;
            margin-bottom: 25px;
        }

        &.revers {
            flex-direction: row-reverse;
            @include breakpoint-down(md) {
                flex-direction: column-reverse;
            }

            .right {
                padding-left: 0;
            }
        }

        .left {
            width: 50%;
            @include breakpoint-down(md) {
                width: 100%;
            }

            .img-wrap {
                width: 610px;
                height: 446px;

                @include breakpoint-down(md) {
                    width: 100%;
                    height: auto;
                }

                img {
                    max-width: 100%;
                }
            }
        }

        .right {
            width: 50%;
            padding: 30px 118px 30px 60px;
            @include breakpoint-down(md) {
                width: 100%;
                padding: 15px 0 0 0;
            }

            .info-wrap {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #002554;

                h2 {
                    margin-bottom: 16px;
                }
            }
        }
    }

    .blue-fon {
        background-color: #002554;
        height: 112px;
    }
}

.line-block {
    border: 13px solid #F8F8F8;
    border-right: none;
    border-left: none;
    padding: 53px 0 32px;
    @include breakpoint-down(md){
        padding: 40px 0 ;
    }

    &-wrap {
        display: flex;
        @include breakpoint-down(md){
            display: block;
        }
    }

    &-item {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #002554;
        padding: 10px 36px 0;
        position: relative;
        @include breakpoint-down(md){
            margin-bottom: 25px;
            padding: 10px 0 0;
        }

        &::after {
            content: '';
            position: absolute;
            width: 131px;
            height: 2px;
            background-color: #002554;
            left: 0;
            right: 0;
            top: 0;
            margin: 0 auto;
        }
    }
}

.courses-list {
    padding: 0;
    list-style: none;
    width: 358px;
    margin-right: 100px;
    @include breakpoint-down(md){
        width: 100%;
        margin: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &-wrap {
        margin: 93px 0 98px;
        display: flex;
        justify-content: center;
        @include breakpoint-down(md){
            margin: 25px 0;
            flex-wrap: wrap;
        }
    }

    li {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #002554;

        .img {
            margin-right: 21px;
        }
    }
}

.advantages {
    padding: 124px 0 107px;
    @include breakpoint-down(md) {
        padding: 50px 0 50px;
    }

    &-wrap {
        display: flex;
        padding: 0 64px;
        @include breakpoint-down(md) {
            padding: 0;
            display: block;
        }
    }

    &-item {
        width: 33.3333%;
        padding: 0 42px;
        @include breakpoint-down(md) {
            width: 100%;
            padding: 0;
            display: flex;
            margin-bottom: 20px;
        }

        .img {
            width: 80px;
            height: 80px;
            margin: 0 auto 8px;
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #002553;
            @include breakpoint-down(md) {
                text-align: left;
                padding-left: 20px;
            }
        }
    }
}

.brands {
    background: #F2F2F2;
    padding: 20px 0 67px;

    &-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @include breakpoint-down(md){
            justify-content: flex-start;

            img{
                max-width: 120px;
                margin: 20px;
            }
        }
    }
}

.block-icon-info {
    display: flex;
    margin-bottom: 70px;
    align-items: flex-end;
    @include breakpoint-down(md) {
        display: block;
        margin-bottom: 25px;
    }

    .title-info-wrap {
        box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
        padding: 16px 20px 6px 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #002554;
        position: relative;
        z-index: 3;
        background-color: #fff;

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 25px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 373px;
                bottom: -10px;
                height: 2px;
                background-color: #002554;
                @include breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }

    .left {
        width: 640px;
        margin-right: 73px;
        margin-left: 100px;
        position: relative;
        @include breakpoint-down(md) {
            width: 100%;
            margin: 0;
        }

        &::after {
            content: '';
            position: absolute;
            height: 13px;
            left: -100vw;
            top: 40px;
            right: 0;
            background: #F8F8F8;
        }

        &::before {
            content: '';
            position: absolute;
            height: 13px;
            left: -100vw;
            top: 290px;
            right: 0;
            background: #F8F8F8;
        }
    }

    .right {
        .img-title-wrap {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            padding: 84px 0;
            position: relative;
            color: #002554;
            margin-bottom: 15px;
            @include breakpoint-down(md) {
                padding: 50px 0;
            }

            &::after {
                content: '';
                position: absolute;
                height: 13px;
                right: -100vw;
                top: 0;
                left: -80px;
                background: #F8F8F8;
            }

            &::before {
                content: '';
                position: absolute;
                height: 13px;
                right: -100vw;
                bottom: 0;
                left: -80px;
                background: #F8F8F8;
            }

            .img {
                margin-right: 26px;
            }
        }
    }
}

.block-icon-info.reverse {
    flex-direction: row-reverse;
    align-items: flex-start;

    .right .img-title-wrap {
        margin-top: 15px;

        &::after {
            left: -100vw;
            right: -100px;
        }

        &::before {
            left: -100vw;
            right: -100px;
        }
    }

    .left {
        &::before {
            left: 0;
            right: -100vw;
            top: auto;
            bottom: 40px;
        }

        &::after {
            left: 0;
            right: -100vw;
            bottom: 290px;
            top: auto;
        }
    }
}

.info-block-without-line {
    display: flex;
    align-items: center;
    margin: 40px 0 83px;
    @include breakpoint-down(md) {
        display: block;
        margin: 40px 0 40px;
    }

    .left {
        width: 50%;
        margin-right: 166px;
        @include breakpoint-down(md) {
            width: 100%;
            margin-bottom: 25px;
        }

        .info {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #002554;
            padding: 34px 80px 34px 0;
            position: relative;
            @include breakpoint-down(md) {
                padding: 0;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                background-color: #002554;
                width: 373px;
                @include breakpoint-down(md) {
                    display: none;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 2px;
                background-color: #002554;
                width: 373px;
                @include breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    .right {
        width: 50%;
        @include breakpoint-down(md) {
            width: 100%;
        }

        .wrap {
            display: flex;
            align-items: center;
        }

        .img {
            margin-right: 26px;
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #002553;
        }
    }
}
