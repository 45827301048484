.partners-page {
    padding-bottom: 105px;
    @include breakpoint-down(md){
        padding-bottom: 80px;
    }

    .show-more {
        margin-bottom: 45px;
    }

    &__top-block {
        min-height: 323px;
        background: #002554;
        padding-top: 140px;
        color: #fff;
        @include breakpoint-down(md){
            padding-top: 80px;
            min-height: 260px;
        }
    }

    .title-img {
        width: 722px;
        min-height: 482px;
        position: relative;
        margin: 0 auto 52px;
        padding: 0 0 84px 59px;
        display: flex;
        align-items: flex-end;
        @include breakpoint-down(md){
            width: 100%;
            min-height: 280px;
            padding: 0 0 30px 30px;
        }

        &.top-offset {
            margin-top: -110px;
        }

        img {
            @include stretch();
            height: 100%;
            max-width: 100%;
        }

        h2 {
            color: #fff;
            position: relative;
            z-index: 5;

            &::before {
                content: '';
                position: absolute;
                width: 391px;
                background-color: #fff;
                height: 2px;
                left: 0;
                top: -10px;
                @include breakpoint-down(md){
                    width: 100%;
                }
            }
        }
    }

    .partners-wrap {
        display: flex;
        margin: -10px;
        @include breakpoint-down(md){
            display: block;
        }

        .partner-item-wrap {
            width: 25%;
            padding: 10px;
            @include breakpoint-down(md){
                width: 100%;
            }
        }

        .partner-item {
            background: #FFFFFF;
            box-shadow: 0 5px 130px rgba(0, 0, 0, 0.07);
            padding: 17px 23px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .img {
                height: 148px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.428571px;
                text-transform: uppercase;
                color: #D50032;
                margin-bottom: 5px;
                height: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                line-clamp: 3;
            }

            .desc {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: #3A3A44;
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -moz-box;
                -moz-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-clamp: 3;
                box-orient: vertical;
                height: 70px;
            }

            .more-details {
                background: #002554;
                border-radius: 5px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                height: 32px;
                width: 85%;
            }
        }
    }
}

.modal-content{
    @include breakpoint-down(md){
        padding: 20px 20px 30px;
    }
}

.partner-modal {
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 920px;

        }
    }

    .top-info{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include breakpoint-down(md){
            display: block;
            margin-bottom: 25px;
        }

        .left{
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include breakpoint-down(md){
                width: 100%;
                height: 130px;
            }
            .img {
                width: 200px;
                display: flex;
                height: 160px;
                align-items: center;
                justify-content: center;
                @include breakpoint-down(md){
                    width: 100%;
                    height: auto;
                }
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .right{
            width: calc(100% - 250px);
            @include breakpoint-down(md){
                width: 100%;
                padding-left: 0;
            }

            .desc{
                width: 100%;
            }
        }
    }

    .right {
        padding-left: 20px;
        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: #002554;
            position: relative;
            margin-bottom: 20px;

            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: -10px;
                height: 1px;
                width: 275px;
                background-color: #787885;
            }

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                letter-spacing: 0.018em;
                color: #787885;
                margin-right: 10px;
            }
        }

        .subtitle{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 143%;
            letter-spacing: 0.018em;
            color: #787885;
            margin-bottom: 7px;
        }

        .desc{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.0275em;
            color: #002554;
            margin-bottom: 15px;
            width: 298px;
        }

        .site-link{
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            text-decoration-line: underline;
            color: #002554;
        }
    }

    .bottom-info{
        .title{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: #002554;
            position: relative;
            margin-bottom: 28px;

            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: -10px;
                height: 1px;
                width: 275px;
                background-color: #787885;
            }
        }

        .desc{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #002554;
        }
    }

    .teachers{
        padding-top: 30px;

        &-item{
            display: flex;
            margin-bottom: 28px;
            @include breakpoint-down(md){
                display: block;

                .right{
                    padding-left: 0;
                }
            }

            .img{
                width: 263px;
                height: 311px;
                position: relative;
                z-index: 6;
                background-color: #F4F4F4;
                display: flex;
                align-items: center;
                justify-content: center;
                @include breakpoint-down(md){
                    height: 230px;
                    width: 230px;
                    margin: 0 auto 20px;
                }
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .name{
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                width: 285px;
                height: 58px;
                display: flex;
                align-items: center;
                padding-left: 14px;
                background: #D50032;
                margin-bottom: 8px;
                @include breakpoint-down(md){
                    margin: 0 auto 8px;
                    justify-content: center;
                }
            }

            .position,
            .experience{
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.0275em;
                color: #002554;

                span{
                    color: #787885;
                    margin-right: 10px;
                }
            }

            .desc{
                width: 466px;
                margin-top: 24px;
                position: relative;
                padding: 25px 25px 25px 0;
                min-height: 179px;
                @include breakpoint-down(md){
                    width: 100%;
                    padding: 15px 0;
                }

                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: -40px;
                    right: 0;
                    background: #F4F4F4;
                    @include breakpoint-down(md){
                        left: -20px;
                        right: -20px;
                    }
                }

                >*{
                    position: relative;
                    z-index: 3;
                }
            }
        }
    }
}
