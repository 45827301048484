.marketplace-page {
  padding-bottom: 83px;
  .blue-fon {
    background: #002554;
    padding: 140px 0 93px;
    margin-bottom: 30px;
    color: #fff;
      @include breakpoint-down(md){
          padding: 70px 0 43px;
      }

    h2{
      margin-bottom: 5px;
    }

    .regular-font{
      color: #fff;
      width: 397px;
        @include breakpoint-down(md){
            width: 100%;
        }
    }
  }

  .course-item-wrap{
    width: 33.3333%;
      @include breakpoint-down(md){
          width: 100%;
      }
  }
}

.marketplace-wrap{
  display: flex;
  justify-content: space-between;

  .left{
    width: 25%;
      @include breakpoint-down(md){
          position: fixed;
          background: #fff;
          z-index: 4;
          width: 300px;
          left: 0;
          top: 60px;
          overflow: auto;
          bottom: 0;
          padding: 30px 10px 30px 30px;
          border-top: solid 2px #D50032;
          box-shadow: 0 0 61px transparent;
          transform: translate(-100%, 0);
          transition: all .2s ease-in-out;

          &.show-filter{
              transform: translate(0, 0);
              box-shadow: 0 0 61px #00000085;
          }
      }
  }

  .right{
    width: 75%;
      @include breakpoint-down(md){
          width: 100%;
      }
  }
}

.course {
  &__items {
    display: flex;
    flex-wrap: wrap;
  }
}
