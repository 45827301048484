.about-top-banner {
    background: $primaryFirst;
    min-height: 650px;
    padding-top: 167px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 100%;
        left: 0;
        border-top: 403px solid #F5F5F5;
        border-right: 960px solid transparent;
        z-index: -1;
        @include breakpoint-down(md){
            display: none;
        }
    }
    @include breakpoint-down(md){
        padding-top: 77px;
        min-height: auto;
    }

    &__inside {
        display: flex;
        justify-content: space-between;
        @include breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &__left {
        width: 50%;
        @include breakpoint-down(md){
            width: 100%;
            margin-bottom: 30px;
        }

        .img {
            height: 407px;
            position: relative;
            @include breakpoint-down(md){
                height: 255px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 206px;
                right: -103px;
                top: -47px;
                bottom: -40px;
                background: #F5F5F5;
                @include breakpoint-down(md){
                    bottom: -20px;
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: 206px;
                height: 206px;
                left: -99px;
                top: 50%;
                transform: translate(0, -50%);
                background: #F5F5F5;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 3;
            }
        }
    }

    &__right {
        width: 50%;
        color: #fff;
        padding-left: 130px;
        @include breakpoint-down(md){
            width: 100%;
            padding-left: 0;
            padding-bottom: 30px;

            &.text-center{
                text-align: left !important;
            }
        }

        .title {
            margin-bottom: 42px;
        }

        .subtitle {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 27px;
        }

        .description {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.our-target-desc {
    display: flex;
    justify-content: space-between;
    color: $primaryFirst;
    margin-bottom: 166px;
    @include breakpoint-down(md){
        margin-bottom: 35px;
        flex-direction: column-reverse;
    }

    &__left {
        padding: 145px 77px 0 0;
        @include breakpoint-down(md){
            padding: 0;

            &.text-center{
                text-align: left !important;
            }
        }

        .subtitle {
            font-weight: 600;
            margin-bottom: 24px;
        }


        .mb50 {
            margin-bottom: 50px;
        }
    }

    &__right {
        width: 625px;
        height: 524px;
        @include breakpoint-down(md){
            width: 100%;
            height: auto;
            margin: 20px 0 30px;
            position: relative;
        }

        .img {
            width: 100%;
            height: 100%;

            @include breakpoint-down(md){
                text-align: center;

                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}

.regular-font {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #002554
}

.targets {
    margin-bottom: 180px;
    @include breakpoint-down(md){
        margin-bottom: 20px;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #002554;
        margin-bottom: 102px;
        position: relative;
        @include breakpoint-down(md){
            margin-bottom: 60px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -36px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #002554;
            width: 447px;
            margin: 0 auto;
            @include breakpoint-down(md){
                width: 100%;
            }
        }
    }

    .info-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 287px;
        @include breakpoint-down(md){
            flex-direction: column;
            margin-bottom: 25px;
        }

        .right,
        .left {
            width: 44%;
            @include breakpoint-down(md){
                width: 100%;
            }
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;

        .img {
            margin-right: 26px;
            width: 30px;
            height: 30px;
            @include breakpoint-down(md){
                margin-right: 5px;
            }
        }

        p {
            width: calc(100% - 30px);
        }
    }
}

.img-info-block {
    display: flex;
    @include breakpoint-down(md){
        flex-direction: column;
    }

    &.fixed-img {
        min-height: 392px;
        position: relative;
        justify-content: flex-end;
        @include breakpoint-down(md){
            flex-direction: column;
        }

        .img {
            width: 624px;
            height: 526px;
            position: absolute;
            left: 0;
            bottom: 0;
            @include breakpoint-down(md){
                position: relative;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
            }
        }

        .info-wrap {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                border-top: 150px solid #002554;
                border-right: 180px solid transparent;
                @include breakpoint-down(md){
                    display: none;
                }
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 0;
                border-bottom: 150px solid #002554;
                border-right: 180px solid transparent;
                @include breakpoint-down(md){
                    display: none;
                }
            }
        }
    }

    &.revers {
        flex-direction: row-reverse;
        @include breakpoint-down(md){
            flex-direction: column;
        }
    }

    .img {
        width: 624px;
        height: 526px;
        @include breakpoint-down(md){
            width: 100%;
            height: auto;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .info {
        text-align: center;
        width: 520px;
        padding: 0 10px;
        color: $primaryFirst;
        @include breakpoint-down(md){
            width: 100%;
            padding: 0;
        }

        &.bold {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
        }

        h2 {
            position: relative;
            margin-bottom: 76px;
            @include breakpoint-down(md){
                margin-bottom: 25px;
            }

            &::after {
                content: '';
                height: 2px;
                position: absolute;
                bottom: -39px;
                width: 289px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: #002554;
                @include breakpoint-down(md){
                    width: 100%;
                    bottom: -10px;
                }
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
        }

        &-wrap {
            width: calc(100% - 624px);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            @include breakpoint-down(md){
                width: 100%;
                margin: 25px 0;
            }
        }
    }
}


.gray-fon-block {
    background: #F5F5F5;
}

.blue-fon-block {
    background: #002554;

    .mission {
        padding: 130px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #FFFFFF;
        @include breakpoint-down(md){
            padding: 50px 0;
            margin: 25px 0px;
        }

        .info-wrap {
            margin: 0 auto;
            width: 880px;
            @include breakpoint-down(md){
                width: 100%;
                font-size: 17px;
            }
        }

        h2 {
            position: relative;
            margin-bottom: 76px;
            @include breakpoint-down(md){
                margin-bottom: 25px;
            }

            &::after {
                content: '';
                height: 2px;
                position: absolute;
                bottom: -39px;
                width: 289px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: #fff;
                @include breakpoint-down(md){
                    width: 100%;
                    bottom: -12px;
                }
            }
        }
    }
}

.red {
    color: #DB0632;
}

.with-triangle{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 100%;
        left: 0;
        border-top: 403px solid #F5F5F5;
        border-right: 960px solid transparent;
        z-index: -1;
        @include breakpoint-down(md){
            display: none;
        }
    }
}
