.detail-news{
  padding-bottom: 40px;
  .top-block{
    background: #002554;
    min-height: 323px;
    padding-top: 164px;
      @include breakpoint-down(md){
          min-height: 240px;
          padding-top: 80px;
      }
  }

  h2{
    display: inline-block;
    position: relative;
    margin-bottom: 55px;
      @include breakpoint-down(md){
          margin-bottom: 45px;
          font-size: 22px;
      }

    &::after{
      content: '';
      position: absolute;
      left: -17%;
      right: -17%;
      bottom: -35px;
      height: 2px;
      background-color: #F3F3F3;
        @include breakpoint-down(md){
            left: 0;
            right: 0;
            bottom: -15px;
        }
    }
  }

  h3{
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #002554;
  }

  .info-wrap{
    padding: 0 60px;
      @include breakpoint-down(md){
          padding: 0;
      }
  }
}

.back-btn{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002554;
  background: #FFFFFF;
  border-radius: 5px;
  width: 187px;
  height: 42px;
}

.detail-news-img{
  height: 396px;
  margin: -90px 0 20px;
    @include breakpoint-down(md){
        height: 200px;
        img{
            max-width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.detail-hews-p{
  margin-bottom: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #002554;

  p:last-child{
    margin-bottom: 0;
  }
}
