.filter {

    #send-filter {
        border: 1px solid #002554;
        background: #002554;
        color: #fff;
        padding: 7px 14px;
        position: relative;
        top: -30px;
    }
    
    &-head {
        margin-bottom: 28px;
        display: flex;
        @include breakpoint-down(md) {
            margin-bottom: 0;
        }
    }

    &-body {
        .title{
            font-size: 20px !important;
            @include breakpoint-down(md){
                font-size: 16px !important;
            }
        }

        @include breakpoint-down(md) {
            margin-top: 15px;
        }
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        color: #002554;
        margin-bottom: 15px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 40px;
        @include breakpoint-down(md) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 8px;
            padding-right: 28px;
        }

        .title-icon {
            transform: rotateX(0);
            transition: transform .2s ease-in-out;
        }
    }

    .filter-icon {
        width: 18px;
        height: 12px;
        margin-left: 14px;
        transform: translate(0px, 9px);
        @include breakpoint-down(md) {
            transform: translate(0px, 1px);
        }
    }

    ul {
        list-style: none;
        padding: 0 42px 0 0;
        margin: 0;
        color: #002554;
        font-style: normal;
        font-weight: normal;
        @include breakpoint-down(md) {
            padding: 0 30px 0 0;
        }

        li {
            label {
                color: inherit;
                font-size: 15px;
                line-height: 1.2;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                @include breakpoint-down(md) {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 16px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.custom-checkbox {
    position: relative;
    margin: 0 !important;

    &::before {
        content: '';
        position: absolute;
        top: -2.5px;
        bottom: -2.5px;
        right: -2.5px;
        left: -2.5px;
        background: #fff;
        border: solid 2px #002554;
        border-radius: 4px;
        transition: background .2s ease-in-out;
    }

    &:checked {
        &::before {
            background-color: #002554;
        }

        &::after {
            opacity: 1;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -2.5px;
        bottom: -2.5px;
        right: -2.5px;
        left: -2.5px;
        background-image: url("/images/check-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
    }
}

@include breakpoint-down(md) {
    .mobile-filter-trigger {
        writing-mode: tb-rl;
        background: #002554;
        color: #D50032;
        position: fixed;
        left: 0;
        top: 237px;
        transform: rotate(-180deg);
        font-size: 14px;
        border-radius: 5px 0 0 5px;
        padding: 10px 0 10px 2px;
        font-weight: 800;
    }

    .close-filter {
        position: absolute;
        right: 13px;
        top: 13px;
        z-index: 4;
        height: 20px;
        width: 20px;
    }
}

.filter-item-wrap {
    @include breakpoint-down(md){
        margin-bottom: 20px;
    }

    ul {
        max-height: 0;
        overflow: hidden;
        transition: all .5s ease;
    }

    &.active-item {
        ul {
            max-height: 1000px;
        }

        .title .title-icon {
            transform: rotateX(-180deg);
        }
    }
}
