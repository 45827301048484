.our-students {
    padding-bottom: 200px;
    @include breakpoint-down(md){
        padding-bottom: 80px;
    }

    .show-more {
        margin-top: 0;
    }

    &__top-banner {
        min-height: 323px;
        background-color: $primaryFirst;
        color: #fff;
        padding-top: 140px;
        margin-bottom: 50px;
        @include breakpoint-down(md){
            padding-top: 80px;
            padding-bottom: 30px;
            min-height: auto;
        }

        h2 {
            margin-bottom: 4px;
        }

        .info {
            width: 360px;
            @include breakpoint-down(md){
                width: 100%;
            }
        }
    }

    &__top-banner-subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
    }

    &__item-wrap {
        margin-bottom: 91px;
        @include breakpoint-down(md){
            margin-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__items {
        margin-bottom: 82px;
        @include breakpoint-down(md){
            margin-bottom: 40px;
        }
    }

    &__item {
        display: flex;
        padding-right: 40px;
        @include breakpoint-down(md) {
            display: block;
            padding-right: 0;
        }

        .avatar {
            width: 300px;
            height: 350px;
            @include breakpoint-down(md) {
                width: 100%;
                height: 240px;
                text-align: center;
            }
    
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }

        .info-about-student {
            padding-left: 78px;
            @include breakpoint-down(md){
                padding-left: 0;
                margin-top: 15px;
            }
        }

        .student-name {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: #D50032;
            margin-bottom: 5px;
        }

        .position {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 143%;
            letter-spacing: 0.018em;
            color: #D50032;
            position: relative;
            display: inline-block;
            padding-top: 5px;
            margin-bottom: 45px;
            @include breakpoint-down(md){
                margin-bottom: 25px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 10%;
                height: 1px;
                background: #D50032;
            }
        }

        .description {
            margin-bottom: 56px;
            position: relative;
            @include breakpoint-down(md){
                margin-bottom: 25px;
            }

            &::after {
                content: '';
                position: absolute;
                left: -70px;
                top: -3px;
                background-image: url('/images/quote.svg');
                width: 51px;
                height: 43px;
                transform: rotate(180deg);
            }

            &::before {
                content: '';
                position: absolute;
                right: -50px;
                top: -3px;
                background-image: url('/images/quote.svg');
                width: 51px;
                height: 43px;
            }

            &-wrap {
                padding: 46px 93px 22px 50px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #002554;
                position: relative;
                @include breakpoint-down(md){
                    padding: 20px 15px 25px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    left: -52px;
                    background: #F4F4F4;
                    border-radius: 5px;
                    @include breakpoint-down(md){
                        left: 0;
                    }
                }


                .details-link {
                    font-size: 16px;
                    line-height: 20px;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        height: 1px;
                        background-color: #002554;
                    }
                }
            }
        }
    }
}

.reviews {
    margin-bottom: 218px;
    @include breakpoint-down(md){
        margin-bottom: 80px;
    }

    .h2-wrap {
        margin-bottom: 64px;
        @include breakpoint-down(md){
            margin-bottom: 25px;
        }
    }

    .swiper-wrapper {
        margin-bottom: 45px;
        @include breakpoint-down(md){
            margin-bottom: 15px;
        }
    }

    .swiper-container {
        margin-bottom: 26px;
    }
}

