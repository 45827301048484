@import "normalize";

@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

// Core variables and mixins
@import "functions";
@import "variables";
@import "variables-responsive";

// Mixins
@import "mixins";
@import "mixins/breakpoints";
@import "mixins/grid";

// Shared components
@import "fonts";
@import "icons";

// Core CSS
@import "reboot";
@import "type";
@import "helpers";
@import "grid";
@import "flex";
@import "masonry";
@import "tables";
@import "forms";
@import "buttons";
@import "tabs";
@import "layout";
@import "modal";
@import "carousels";


// Components
@import "./components/main";
@import "./components/main-page";
@import "./components/our-suggestions";
@import "./components/our-teaching-approaches";
@import "./components/courses";
@import "./components/about";
@import "./components/contacts";
@import "./components/about-platform";
@import "./components/our-students";
@import "./components/marketplace";
@import "./components/filter";
@import "./components/sorting";
@import "./components/faq-page";
@import "./components/news";
@import "./components/detail-news";
@import "./components/professional-certifications";
@import "./components/certificate-detail";
@import "./components/contract-offer";
@import "./components/enterprise-solutions";
@import "./components/privacy-policy";
@import "./components/partners";
@import "./components/subscription";
@import "./components/uof-principles";
@import "./components/404-page";
