.about-platform {
    &__top-banner {
        min-height: 654px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 130px;
        @include breakpoint-down(md) {
            min-height: 484px;
            margin-bottom: 25px;
        }

        .info {
            display: flex;
            justify-content: center;
            padding-top: 275px;
            @include breakpoint-down(md) {
                padding-top: 85px;
                margin-bottom: 30px;
            }

            &-inside {
                color: #fff;
                max-width: 940px;
                text-align: center;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: -58px;
                    bottom: -25px;
                    background-image: url("/images/angle.svg");
                    width: 140px;
                    height: 140px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: -58px;
                    top: 0;
                    transform: rotate(180deg);
                    background-image: url("/images/angle.svg");
                    width: 140px;
                    height: 140px;
                }
            }
        }

        .title {
            position: relative;
            margin-bottom: 35px;
            @include breakpoint-down(md) {
                margin-bottom: 20px;
            }
        }

        .description {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            @include breakpoint-down(md) {
                font-size: 20px;
                line-height: 25px;
                padding-bottom: 35px;
            }
        }
    }
}

.opportunities-platform {
    padding-top: 29px;

    &-items {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin: 26px 0 130px;
        position: relative;
        @include breakpoint-down(md) {
            flex-direction: column;
            margin-bottom: 25px;
        }

        .left {
            width: 32%;
            @include breakpoint-down(md) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .right {
            width: 66%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 4;
            @include breakpoint-down(md) {
                width: 100%;
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: #FFF;
            padding: 16px 28px 19px;
            background: #002554;
            border-radius: 5px;
            display: inline-block;
            position: relative;
            z-index: 3;
            @include breakpoint-down(md) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .portrait {
            height: 605px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            @include breakpoint-down(md) {
                height: 315px;
            }
        }

        .albom {
            height: 289px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            @include breakpoint-down(md) {
                margin-bottom: 20px;
                height: 315px;
            }
        }
    }
}

.title-bottom-left {
    display: flex;
    align-items: flex-end;

    .title-wrap {
        position: relative;

        &::after {
            content: '';
            left: 0;
            right: -124px;
            top: -14px;
            bottom: 0;
            position: absolute;
            background: rgba(255, 255, 255, 0.63);
        }
    }
}

.title-top-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @include breakpoint-down(md) {
        align-items: flex-end;
        justify-content: flex-start;
    }

    .title-wrap {
        position: relative;

        &::after {
            content: '';
            right: 0;
            left: -124px;
            bottom: -14px;
            top: 0;
            position: absolute;
            background: rgba(255, 255, 255, 0.63);
            @include breakpoint-down(md) {
                left: 0;
                right: -124px;
                bottom: 0;
                top: -14px;
            }
        }
    }
}

.opportunities-platform-info {
    color: $primaryFirst;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    z-index: 4;
    margin-bottom: 102px;

    h2 {
        margin-bottom: 57px;
        @include breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }

    &_inside {
        display: flex;
        @include breakpoint-down(md) {
            flex-direction: column;
        }

        .right,
        .left {
            width: 50%;
            padding-right: 107px;
            @include breakpoint-down(md) {
                width: 100%;
                padding: 0;
            }
        }
    }
}

.subscriptions {
    h2 {
        margin-bottom: 18px;
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        width: 500px;
        color: #002554;
    }
}

.subscriptions {
    &__items {
        margin: 75px 0;
        display: flex;
    }

    &__item {
        padding: 0 8px;

        &-inside {
            background: #FFFFFF;
            box-shadow: 0 5px 130px rgba(0, 0, 0, 0.0788625);
        }

        .price {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 60px;
            text-align: center;
            letter-spacing: 0.428571px;
            text-transform: uppercase;
            color: #D50032;
        }
    }
}
