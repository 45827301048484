.privacy-policy-page{
    padding-bottom: 300px;
    @include breakpoint-down(md){
        padding-bottom: 100px;
    }

    &__top-block{
        min-height: 297px;
        background-color: #002554;
        padding-top: 114px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #FFF;
        margin-bottom: 45px;
        @include breakpoint-down(md){
            padding-top: 80px;
            padding-bottom: 30px;
        }

        h2{
            margin-bottom: 6px;
        }

        .desc{
            width: 612px;
            @include breakpoint-down(md){
                width: 100%;
            }
        }
    }
}

.privacy-policy{
    &__info-wrap{
        display: flex;
        @include breakpoint-down(md){
            display: block;
        }

        .left{
            width: 40%;
            @include breakpoint-down(md){
                width: 100%;
            }
        }

        .right{
            width: 60%;
            @include breakpoint-down(md){
                width: 100%;
                margin-top: 35px;
            }
        }

        .nav-tabs,
        .nav-tabs .nav-link{
            border: none;
            background: transparent;
        }

        .nav-link{
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            display: flex;
            justify-content: space-between;

            .img{
                transition: transform .2s ease-in-out;
                transform-origin: center;
                transform: rotateY(0deg);

                svg{
                    transform: rotate(-90deg);
                }
            }

            &.active{
                .img{
                    transform: rotateY(-180deg);
                }
            }
        }

        .nav{
            flex-direction: column;

            .nav-item{
                border-bottom: 2px solid #002554;
            }

            .nav-item,
            .nav-item.active{
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: #002554;
            }
        }

        .tab-pane{
            padding-left: 30px;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            color: #002554;
            @include breakpoint-down(md){
                padding: 0;

                button{
                    margin: 0 auto;
                    display: block;
                }
            }

            p{
                margin-bottom: 24px;
            }

            .title{
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 22px;
            }
        }
    }
}
