.news-page {
    padding-bottom: 82px;

    .top-block {
        min-height: 323px;
        background-color: $primaryFirst;
        margin-bottom: 28px;
        padding-top: 139px;
        color: #fff;
        @include breakpoint-down(md) {
            min-height: 210px;
            padding-top: 60px;
        }

        h2 {
            margin-bottom: 6px;
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            width: 398px;
            @include breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .show-more {
        margin-top: 0;
    }
}

.news {
    &__wrap {
        padding-top: 35px;
        border-top: 1px solid #F0F0F0;
    }

    &__item {
        display: block;
        padding: 21px 23px 30px;
        background-color: #fff;
        box-shadow: 0 5px 130px rgba(0, 0, 0, 0.0788625);
        margin: 0 auto 56px;
        width: 930px;
        @include breakpoint-down(md){
            width: 100%;
            margin: 0 auto 25px;
        }

        .img {
            position: relative;
            margin-bottom: 15px;
            height: 332px;
            @include breakpoint-down(md){
                height: 180px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include breakpoint-down(md){
                    object-fit: cover;
                }
            }
        }

        .date {
            background: #D50032;
            padding: 6px 41px;
            position: absolute;
            left: 9px;
            bottom: 22px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 143%;
            letter-spacing: 0.018em;
            color: #FFFFFF;
        }

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            color: #D50032;
            margin-bottom: 10px;
            @include breakpoint-down(md){
                font-size: 16px;
            }
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #3A3A44;
        }
    }
}
