.our-teaching-approaches {
    margin: 67px 0 130px;
    @include breakpoint-down(md){
        margin: 17px 0 40px;
    }

    &__items {
        display: flex;
        margin-bottom: 100px;
        justify-content: space-between;
        flex-wrap: wrap;
        @include breakpoint-down(md){
            margin-bottom: 0;
        }
    }

    &__item-wrap {
        padding-top: 20px;
        width: 323px;
        position: relative;
        min-height: 412px;
        display: flex;
        justify-content: center;
        @include breakpoint-down(md){
            width: 100%;
            margin-bottom: 30px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 198px;
            height: 198px;
            left: 0;
            top: 0;
            background: #E8E8E8;
        }

        &::before {
            content: '';
            position: absolute;
            width: 56px;
            right: 0;
            top: 0;
            bottom: 0;
            background: #E8E8E8;
        }
    }

    &__item {
        width: 262px;
        height: 100%;
        z-index: 2;
        position: relative;
        @include breakpoint-down(md){
            width: 95%;
        }

        &::after {
            content: '';
            @include stretch(13px, 9px, 19px, 9px);
            background: rgba(0, 0, 0, 0.5);
        }

        .info {
            text-align: center;
            color: #FFF;
            position: relative;
            z-index: 3;
            padding: 60px 9px 0;
            font-weight: 500;
        }

        .title {
            font-size: 24px;
            line-height: 29px;
            position: relative;
            min-height: 60px;
            margin-bottom: 18px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 2px;
                bottom: 0;
                width: 160px;
                margin: 0 auto;
                background: #D50032;
            }
        }

        .description {
            padding: 0 8px;
            font-size: 18px;
            line-height: 22px;
        }
    }
}
