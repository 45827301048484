.certificates-page {
    padding-bottom: 110px;

    .top-block {
        min-height: 323px;
        background: #002554;
        padding-top: 140px;
        margin-bottom: 58px;
        @include breakpoint-down(md) {
            padding-top: 70px;
            padding-bottom: 30px;
            min-height: 200px;
        }

        h2 {
            color: #fff;
        }
    }
}

.certificate__item {
    box-shadow: 0 5px 130px rgba(0, 0, 0, 0.0788625);
    padding: 10px 29px 51px;

    .img {
        height: 213px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.428571px;
        text-transform: uppercase;
        color: #D50032;
        margin-bottom: 5px;
        height: 100px;
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #002554;
        border-bottom: 2px solid #002554;
        padding-bottom: 5px;
        margin-bottom: 25px;
        height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;
    }
}

.certificate__item-wrap {
    width: 33.3333%;
    padding: 12px 21px;
    @include breakpoint-down(md) {
        width: 100%;

        .btn__primary {
            width: 95%;
        }
    }
}

.certificate__wrap {
    display: flex;
    flex-wrap: wrap;
    margin: -12px -21px;
}
