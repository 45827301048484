* {
	box-sizing: border-box;
}

*::before,
*::after {
	box-sizing: border-box;
}

html {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

figure {
	margin: 0;
}

[role="button"] {
	cursor: pointer;
}

button {
	outline: none !important;
	cursor: pointer;
	padding: 0;
	border: 0;
	background: none;
	font-weight: normal;
}