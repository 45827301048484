@import "./header";
@import "./nav-main";
@import "./footer";


.form-item {
  padding: 0 0 20px 0;
  .label {
    padding: 5px 0;
  }
  input {
    padding: 10px 10px;
  }
}
