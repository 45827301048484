.modal{
  
  &-content{
    border: none;
    border-radius: 0;
    padding: 20px 35px 30px;
    box-shadow: 0 0 14px rgba(0, 0, 0, .1);
  }
  
  &-header{
    padding: 0;
    margin-bottom: 14px;
    border: none;
    
    button{
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.0275em;
      color: #787885;
      
      & div:first-child{
        margin-right: 10px;
      }
    }
  }
  
  &-body{
    padding: 0;
    margin-bottom: 26px;
  }
  
  &-footer{
    justify-content: center;
    padding: 0;
    border: none;
    
    button{
      &:hover{
        color: #fff;
      }
    }
  }
}
