.header {
    padding: 20px 0;
    background: transparent;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    transition: all .3s ease-in-out;
    @include breakpoint-down(md) {
        background: #002554;
        z-index: 6;
    }

    &__inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint-down(md){
            height: 20px;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    .logo {
        margin-right: 48px;
        @include breakpoint-down(md){
            padding-right: 0;
            position: absolute;
            top: 50%;
            z-index: 2;
            width: 110px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: auto;
            transform: translate(0px, -50%);

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}

.logo {
    width: 49px;
    height: 60px;
    display: inline-block;
    //position: relative;
}

.hide-header {
    .header {
        top: -100%;
    }
}

.blue-header {
    .header {
        background: #002554;
    }

    //.white-logo{
    //  opacity: 1;
    //}
    //
    //.transparent-logo{
    //  opacity: 0;
    //}
}


//.white-logo{
//  opacity: 0;
//  transition: opacity .2s ease-in-out;
//  @include stretch();
//}
//
//.transparent-logo{
//  opacity: 1;
//  transition: opacity .2s ease-in-out;
//  @include stretch();
//}

.lang-switch {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;

    .lang-icon {
        width: 30px;
        height: 15px;
        margin-right: 12px;

        @include breakpoint-down(md){
            display: block;
        }
    }

    .dropdown-menu{
        @include breakpoint-down(md){
            position: absolute;
            background-color: #002554;
        }
    }

    .dropdown-link {
        cursor: pointer;
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        @include breakpoint-down(md){
            padding-left: 5px;
        }

        & > div {
            margin-right: 12px;
        }
    }
}

.hide-mobi {
    display: block;
    @include breakpoint-down(md) {
        display: none;
    }
}

.hide-desc {
    display: none;
    @include breakpoint-down(md) {
        display: block;
    }
}

.menu-trigger{
    display: none;
    @include breakpoint-down(md){
        display: block;
        width: 30px;
        height: 30px;
        position: relative;

        div{
            @include stretch();
        }

        .burger{
            opacity: 1;
            transition: opacity .2s ease-in-out;
        }

        .close-menu{
            opacity: 0 !important;
            transition: opacity .2s ease-in-out;
        }
    }
}

.show-menu{
    .menu-trigger{
        .burger{
            opacity: 0 !important;
        }

        .close-menu{
            opacity: 1 !important;
        }
    }
}
