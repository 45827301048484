.contact__top-banner {
    min-height: 541px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 124px;
        background-color: #002554;
        left: 0;
        right: 0;
        top: 0;
        @include breakpoint-down(md) {
            height: auto;
        }
    }

    .info {
        color: #002554;
        padding-top: 195px;
        @include breakpoint-down(md) {
            padding-top: 85px;
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 41px;
            @include breakpoint-down(md) {
                font-size: 26px;
                line-height: 34px;
                margin-bottom: 25px;
            }
        }

        .description {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            justify-content: space-between;
            @include breakpoint-down(md) {
                flex-direction: column;
                padding-bottom: 30px;
            }
        }
    }
}

.manager-item {
    margin-bottom: 36px;

    &:last-of-type {
        margin-bottom: 0;
        @include breakpoint-down(md) {
            margin-bottom: 36px;
        }
    }

    .manager-name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

    .manager-contacts {
        padding-top: 16px;

        a {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            @include breakpoint-down(md) {
                display: flex;
                margin-right: 0;
                margin-bottom: 8px;
            }

            div {
                margin-right: 8px;
            }
        }
    }
}

.subscribe-social-wrap {
    padding-top: 139px;
    margin-bottom: 198px;
    color: #fff;
    display: flex;
    align-items: center;
    @include breakpoint-down(md) {
        padding-top: 40px;
        flex-direction: column;
        margin-bottom: 50px;
    }

    .left {
        width: 415px;
        margin-right: 163px;
        @include breakpoint-down(md) {
            width: 100%;
            margin-right: 0;
        }
    }

    .right {
        @include breakpoint-down(md) {
            width: 100%;
            padding-top: 30px;
        }
    }

    .desc {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .app-social-wrap {
        display: flex;
        align-items: center;
        @include breakpoint-down(md) {
            justify-content: space-around;
        }

        .app-social-link {
            width: 141px;
            height: 141px;
            margin-right: 79px;
            @include breakpoint-down(md) {
                width: 70px;
                height: 70px;
                margin-right: 0;
            }


            &:last-of-type {
                margin-right: 0;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.contact-wrap {
    min-height: 846px;
    padding-top: 43px;
    padding-bottom: 241px;
    background-position: center;
    background-size: cover;
    @include breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 50px;
    }

    h2 {
        color: #fff;
        margin-bottom: 20px;
    }
}

.contacts-form {
    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 38px;
        @include breakpoint-down(md) {
            flex-direction: column;
            margin-bottom: 25px;
        }

        .left {
            width: 49%;
            @include breakpoint-down(md) {
                margin-bottom: 25px;
            }
        }

        .right {
            width: 49%;
        }
    }

    &-bottom {
        margin-bottom: 39px;
        @include breakpoint-down(md) {
            margin-bottom: 25px;

            & + button {
                margin: 0 auto;
                display: flex;
            }
        }
    }

    &-map-wrap {
        display: flex;
        justify-content: space-between;
        @include breakpoint-down(md) {
            flex-direction: column;
        }

        input,
        textarea,
        input:focus,
        textarea:focus {
            color: #fff !important;
        }

        .left {
            width: 49%;
            @include breakpoint-down(md) {
                width: 100%;
            }
        }

        .right {
            width: 49%;
            @include breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.map {
    width: 100%;
    height: 369px;
    margin-top: 30px;
}
