.certificate-detail {
    padding-bottom: 104px;
    @include breakpoint-down(md){
        padding-bottom: 80px;
    }

    &__inside{
        padding-right: 183px;
        @include breakpoint-down(md){
            padding-right: 0;
        }
    }

    .top-block {
        min-height: 323px;
        background: #002554;
        padding-top: 132px;
        @include breakpoint-down(md){
            padding-top: 80px;
            min-height: 270px;
        }
    }

    &__top-block {
        min-height: 268px;
        background: #FFFFFF;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        margin: -120px 0 33px 0;
        display: flex;
        padding: 30px;
        @include breakpoint-down(md){
            padding: 15px;
            flex-direction: column;
        }

        .left {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include breakpoint-down(md){
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .right {
            width: 50%;
            display: flex;
            align-items: center;
            @include breakpoint-down(md){
                width: 100%;
            }

            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: #002554;
                padding-bottom: 13px;
                border-bottom: 1px solid #787885;
                margin-bottom: 10px;

                span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 143%;
                    letter-spacing: 0.018em;
                    color: #787885;
                    margin-right: 8px;
                }
            }

            .desc-title {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 143%;
                letter-spacing: 0.018em;
                color: #787885;
                margin-bottom: 10px;
            }

            .desc {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.0275em;
                color: #002554;
            }

            .link-site{
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 75px;
                text-decoration: underline;
                color: #002554;
                display: inline-block;
            }

        }
    }

    .info-wrap {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #002554;
        margin-bottom: 98px;
        @include breakpoint-down(md){
            margin-bottom: 30px;
        }

        h1, span, p{
            color: #002554 !important;
        }

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: #002554;
            margin-bottom: 8px;

            &.bottom-line {
                margin-bottom: 27px;
                position: relative;
                display: inline-block;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    right: -110px;
                    background-color: #787885;
                    height: 1px;
                }
            }
        }
    }
}
