@font-face {
  font-family: 'iconfont';
  src: 	url('#{$path-fonts}/iconfont.ttf') format('truetype'),
    url('#{$path-fonts}/iconfont.woff') format('woff'),
    url('#{$path-fonts}/iconfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
