// Masonry

.masonry {
	column-count: 0;
	column-gap: 0;
	counter-reset: brick-counter;
	
	@include breakpoint-up(sm) {
		column-count: $masonry-columns-count-sm;
	}
	
	@include breakpoint-up(md) {
		column-count: $masonry-columns-count-md;
	}
	
	@include breakpoint-up(lg) {
		column-count: $masonry-columns-count-lg;
	}
	
	@include breakpoint-up(xl) {
		column-count: $masonry-columns-count-xl;
	}
}

.brick {
	box-sizing: border-box;
	break-inside: avoid;
	counter-increment: brick-counter;
}
	
.img-masonry {
	width: 100%;
	height: 100%;
	padding-left: ($gutter-width / 2) !important;
	padding-right: ($gutter-width / 2) !important;
	object-fit: cover;
	
	&-ng {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}