html {
    font-size: 16PX;
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: bold;
}

h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    @include breakpoint-down(md){
        font-size: 29px;
    }
}

p {
    margin: 0 0 16px;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 16px;

    ul,
    ol {
        margin-bottom: 0;
    }
}

a {
    color: inherit;
    text-decoration: none;
    outline: none !important;
    transition: .2s color;

    &:hover,
    &:focus {
        color: #DB0632 !important;
        text-decoration: none;
    }

}

button {
    transition: .2s color;

    &:hover {
        color: #DB0632 !important;
    }
}

.list-unstyled {
    @include list-unstyled;
}

.list-inline {
    @include list-unstyled;
    display: flex;
    margin-bottom: 0;
}

.list-justify {
    @include list-unstyled;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: justify;

    > li {
        display: inline-block;
    }

    &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0;
    }
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted;
}

.first-primary-color {
    color: $primaryFirst;
}

h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;

    @include breakpoint-down(md){
        font-size: 31px;
        line-height: 39px;
    }


    &.underline {
        position: relative;
        display: inline-block;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background: #fff;
            bottom: -20px;
        }
    }
}

.h2-wrap {
    width: 938px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    @include breakpoint-down(md){
        width: 100%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 3px;
        background-color: $primaryFirst;
    }

    h2 {
        background: white;
        position: relative;
        z-index: 3;
        padding: 0 10px;
        min-width: 610px;
        text-align: center;
        @include breakpoint-down(md){
            min-width: 100%;
        }
    }
}
