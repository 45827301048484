.faq-page {
    .blue-fon {
        padding: 140px 0 50px;
        background: #002554;
        color: #fff;
        min-height: 323px;
        margin-bottom: 26px;
        @include breakpoint-down(md) {
            padding: 80px 0 30px;
            min-height: 160px;
        }
    }

    &__bottom {
        min-height: 427px;
        color: #FFF;
        padding-top: 48px;
        @include breakpoint-down(md) {
            padding-top: 25px;
            padding-bottom: 25px;

            button{
                display: block;
                margin: 0 auto;
            }
        }

        h2 {
            margin-bottom: 21px;
        }

        .desc {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 40px;
            width: 460px;
            @include breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.faq-form {
    input {
        background: #FFFFFF;
        border-radius: 5px;
        width: 285px;
        height: 58px;
        border: none;
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        padding: 0 17px;
        color: #002554;
        @include breakpoint-down(md){
            width: 100%;
        }

        &::placeholder {
            color: #002554;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
        }
    }

    .input-wrap {
        display: flex;
        margin-bottom: 36px;
        @include breakpoint-down(md){
            display: block;
        }
    }

    .input-item {
        margin-right: 26px;
        @include breakpoint-down(md){
            margin-right: 0;
            margin-bottom: 25px;
        }

        &:last-child {
            margin-right: 0;
        }

        label {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.faq-collapse {
    width: 670px;
    margin-bottom: 40px;
    @include breakpoint-down(md) {
        width: 100%;
    }

    .card {
        border: none;
        color: #002554;
    }

    .card-header {
        border-bottom: 2px solid #002554;
        background-color: transparent;
        padding: 15px 0 6px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
        }

        &__icon {
            width: 14px;
            height: 8px;
            transform: rotate(0);
            transition: transform .2s ease-in-out;
            transform-origin: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        &[aria-expanded="true"] {
            .card-header__icon {
                transform: rotateX(-180deg);
            }
        }
    }

    .card-body {
        padding: 25px 0;
    }

    .collapse {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;

        p:last-child {
            margin-bottom: 0;
        }
    }
}
