.swiper-button-next,
.swiper-button-prev,
.swiper-pagination {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.swiper-pagination{
  margin: 0 35px;
}

.swiper-button-next:after,
.swiper-button-prev:after{
  display: none;
}

.swiper-button-next,
.swiper-button-prev{
  width: 93px;
  margin-top: 0;
}

.arrow-wrap {
  display: flex;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 11px;
  height: 7px;
  border-radius: 0;
  margin: 0 4px;
}

.active-blue {
  .swiper-pagination-bullet-active {
    background: $primaryFirst;
  }
}

.active-red {
  .swiper-pagination-bullet-active {
    background: $primarySecond !important;
  }
  
  .swiper-pagination-bullet{
    background: white;
    opacity: 1;
  }
}

.arrow-end{
  justify-content: flex-end;
}
