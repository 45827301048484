.main-page-top-slider {
    position: relative;

    &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 100%;
        left: 0;
        border-top: 817px solid #F5F5F5;
        border-right: 241px solid transparent;
        @include breakpoint-down(md){
            display: none;
        }
    }

    &::after{
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        width: 0;
        height: 0;
        border-top: 817px solid #F5F5F5;
        border-left: 241px solid transparent;
        @include breakpoint-down(md){
            display: none;
        }
    }

    .arrow-wrap {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0, -70px);
        @include breakpoint-down(md){
            transform: translate(0, -10px);
            justify-content: center;
        }
    }

    .swiper-slide {
        min-height: 630px;
        background-position: center;
        padding-top: 150px;
        @include breakpoint-down(md){
            padding-top: 80px;
            min-height: 100%;
            height: 450px;
            background-size: cover;
        }
    }
}

.main-page-top-slider {
    margin-bottom: 130px;
    @include breakpoint-down(md) {
        margin-bottom: 50px;
    }

    &__info {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #FFF;
        width: 520px;
        @include breakpoint-down(md){
            width: 100%;
        }

        .title {
            margin-bottom: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            height: 88px;
            line-height: 44px;
            @include breakpoint-down(md){
                font-size: 28px;
                line-height: 36px;
                height: auto;
            }
        }

        .img {
            margin-bottom: 10px;
            max-width: 372px;
            max-height: 126px;
            @include breakpoint-down(md){
                width: 160px;
                height: 50px;
            }

            img {
                max-width: 100%;
                height: 100%;
            }
        }

        .description {
            margin-bottom: 41px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
        }

        .author {
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;
        }
    }
}

.pos-r {
    position: relative;
}

.svg-icon {
    display: flex;
    align-items: center;
    transform: translate(20px, 0px);
}
