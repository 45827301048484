// Using @font-face
// Just only three file formats required
// no need to include files with svg or eot extensions
// With these three formats we have pretty good browsers support e.g.
// Chrome 3.5+, Safari 3+, Firefox 3.5+, Opera 10.1+, IE9+, Android 2.2+, iOS 4.3+

// @font-face {
//   font-family: "PT Sans";
//   src:
//     inline("#{$path-fonts}/pt_sans-web-regular-webfont.woff2") format("woff2"),
//     inline("#{$path-fonts}/pt_sans-web-regular-webfont.woff") format("woff");
//     // inline("#{$path-fonts}/pt_sans-web-regular-webfont.ttf")  format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }




@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/Montserrat-Black.woff2') format('woff2'),
  url('#{$path-fonts}/Montserrat-Black.woff') format('woff'),
  url('#{$path-fonts}/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/Montserrat-ExtraLight.woff2') format('woff2'),
  url('#{$path-fonts}/Montserrat-ExtraLight.woff') format('woff'),
  url('#{$path-fonts}/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-Bold.woff2') format('woff2'),
  url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-Bold.woff') format('woff'),
  url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-ExtraBold.woff2') format('woff2'),
  url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-ExtraBold.woff') format('woff'),
  url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-Light.woff2') format('woff2'),
  url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-Light.woff') format('woff'),
  url('#{$path-fonts}/#{$path-fonts}/#{$path-fonts}/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/#{$path-fonts}/Montserrat-Regular.woff2') format('woff2'),
  url('#{$path-fonts}/#{$path-fonts}/Montserrat-Regular.woff') format('woff'),
  url('#{$path-fonts}/#{$path-fonts}/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/#{$path-fonts}/Montserrat-SemiBold.woff2') format('woff2'),
  url('#{$path-fonts}/#{$path-fonts}/Montserrat-SemiBold.woff') format('woff'),
  url('#{$path-fonts}/#{$path-fonts}/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/Montserrat-Medium.woff2') format('woff2'),
  url('#{$path-fonts}/Montserrat-Medium.woff') format('woff'),
  url('#{$path-fonts}/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$path-fonts}/Montserrat-Thin.woff2') format('woff2'),
  url('#{$path-fonts}/Montserrat-Thin.woff') format('woff'),
  url('#{$path-fonts}/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
