// **********************************************************************************
$input-font-size: $font-size-base !default;
$input-border-width: 1px !default;
$input-border-radius: 3px !default;
$input-height-base: 64px !default;
$form-group-margin-bottom:  15px !default;
// *************************************************************************

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	min-width: 0;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	font-size: $font-size-base;
	line-height: inherit;
	border: 0;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  display: inline-block;
  max-width: 100%;
  color: #fff;
}

input[type="search"] {
	box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9; // IE8-9
	line-height: normal;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

output {
	display: block;
	margin: 0;
	padding: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $input-color;
}

.form-control {
	display: block;
	width: 100%;
	height: $input-height-base;
	padding: 6px 12px;
	font-size: $input-font-size;
	line-height: 1.42857143;
	color: $input-color;
	background-color: transparent;
	background-image: none;
  border: 1px solid #787885;
  box-sizing: border-box;
	border-radius: $input-border-radius;
	box-shadow: inset 0 1px 1px rgba(black,.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	outline: none !important;
	&::placeholder {
		color: #fff;
	}

	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}

	&:focus {
		&::placeholder {
			color: #fff;
		}
		$color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
		border-color: $input-border-focus;
		box-shadow: inset 0 1px 1px rgba(black,.075), 0 0 8px $color-rgba;
    background-color: transparent;
	}

	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color: $input-bg-disabled;
		opacity: 1;
	}

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}

}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		&.form-control {
			line-height: $input-height-base;
		}
	}
}

.form-group {
	margin-bottom: $form-group-margin-bottom;
}
