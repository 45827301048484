.uof-principles-page {
    position: relative;

    .uof-principles__top-block {
        min-height: 124px;
        background: #002554;
        margin-bottom: 38px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 100%;
            left: 0;
            border-bottom: 3080px solid #F5F5F5;
            border-right: 6200px solid transparent;
            @include breakpoint-down(md){
                display: none;
            }
        }
    }

    h2 {
        margin-bottom: 65px;
        position: relative;

        &::after {
            content: '';
            left: 0;
            bottom: -35px;
            position: absolute;
            height: 2px;
            background-color: #002554;
            width: 266px;
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #002554;
        margin-bottom: 29px;
        position: relative;
        z-index: 5;
    }

    .approaches-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: -23px -37px 50px;
        position: relative;
        z-index: 4;
        @include breakpoint-down(md){
            display: block;
            margin: 20px 0 70px;
        }

        .approaches-item-wrap {
            width: 50%;
            padding: 23px 37px;
            @include breakpoint-down(md){
                width: 100%;
                padding: 0;
            }
        }

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #D50032;
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #002554;
        }
    }
}

.pie {
    transition: transform .2s ease-in-out;

    &.active{
        transform: translate(0, 0) !important;
    }
}

.diagram-text{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    @include breakpoint-down(md){
        font-size: 6px;
    }
}

.diagramm-wrap {
    height: 745px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 65px;
    margin-bottom: 52px;
    z-index: 5;
    @include breakpoint-down(md){
        height: 336px;
        flex-direction: column;

        & + .subtitle {
            margin-top: 300px;
        }
    }

    .left{
        width: 745px;
        position: relative;
        z-index: 5;
        @include breakpoint-down(md){
            width: 100%;
        }
    }

    .right{
        width: calc(100% - 745px);
        @include breakpoint-down(md){
            width: 100%;
            padding-top: 20px;
        }
    }

    .titles-wrap {
        @include stretch(55px, 52px, 59px, 52px);
        color: #000;
        pointer-events: none;
    }

    .title-top {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 36%;
        pointer-events: none;
        @include breakpoint-down(md){
            height: 16%;
        }

        .left {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 22px;
            @include breakpoint-down(md){
                padding-right: 0;
            }

            .text {
                width: 163px;
                text-align: center;
               @extend .diagram-text;
            }
        }

        .right {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 22px;
            @include breakpoint-down(md){
                padding-left: 0;
            }

            .text {
                width: 163px;
                text-align: center;
                @extend .diagram-text;
                @include breakpoint-down(md){
                    width: 78px;
                    line-height: normal;
                }
            }
        }
    }

    .title-center{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 23%;
        left: 0;
        right: 0;
        height: 42%;
        pointer-events: none;
        //background: #ff000052;

        .left{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 23%;
            @include breakpoint-down(md){
                padding-right: 43%;
            }
            .text{
                width: 123px;
                @extend .diagram-text;
            }
        }

        .right{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 23%;
            @include breakpoint-down(md){
                padding-left: 40%;
            }
            .text{
                width: 123px;
                @extend .diagram-text;
            }
        }
    }

    .title-bottom{
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 42%;
        pointer-events: none;

        .left{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33.333333%;
            padding-left: 12%;
            padding-bottom: 12%;
            @include breakpoint-down(md){
                padding: 0;
            }

            .text{
                width: 163px;
                @extend .diagram-text;
            }
        }

        .center{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33.333333%;
            padding-top: 12%;
            @include breakpoint-down(md){
                padding-top: 32%;
            }

            .text{
                width: 163px;
                @extend .diagram-text;
            }
        }

        .right{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33.333333%;
            padding-right: 12%;
            padding-bottom: 12%;
            @include breakpoint-down(md){
                padding: 0;
            }

            .text{
                width: 163px;
                @extend .diagram-text;
            }
        }
    }

    .main-title {
        font-style: normal;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #002554;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint-down(md){
            font-size: 10px;
            line-height: 12px;
        }

        .text{
            width: 60%;
            text-align: center;
        }
    }
}

.diagramm-info-wrap{
    position: relative;

    .info-item{
        background: #FFFFFF;
        box-shadow: 0 4px 20px 5px rgba(0, 37, 84, 0.1);
        border-radius: 5px;
        padding: 24px 30px 36px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-100%);
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease-in-out, transform .3s ease-in-out;

        &.show{
            opacity: 1;
            transform: translate(0);
            pointer-events: initial;
        }

        .title{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #002554;
            position: relative;
            margin-bottom: 33px;

            &::after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -14px;
                height: 2px;
                background-color: #002554;
                width: 393px;
                margin: 0 auto;
                @include breakpoint-down(md){
                    width: 100%;
                }
            }
        }

        .desc{
            background: #F5F5F5;
            padding: 16px;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #3A3A44;
        }
    }
}

.beads{
    @include stretch();
    pointer-events: none;
    svg{
        pointer-events: none;
        position: absolute;
        right: -2.2%;
        top: 10%;

        circle{
            transition: all .3s ease-in-out;
        }
    }

    @include breakpoint-down(md){
        display: none;
    }
}
