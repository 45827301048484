.sorting-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 1.5px;
        color: #919191;
        margin-right: 14px;
        @include breakpoint-down(md){
            font-size: 14px;
            margin-right: 10px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;

        li {
            margin-right: 18px;

            &:last-child {
                margin-right: 0;
            }

            label {
                cursor: pointer;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 0.018em;
                color: #A9A9A9;
                transition: all .3s ease;
                position: relative;
                margin: 0;
                @include breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 14px;
                }

                input {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                }
            }

            &.active,
            &:hover {
                label {
                    font-style: normal;
                    font-weight: 600;
                    color: #002554;
                }
            }
        }
    }
}
