.subscription-page {
    &__top-block {
        min-height: 323px;
        background: #002554;
        padding-top: 140px;
        color: #fff;
        margin-bottom: 77px;
        @include breakpoint-down(md){
            padding-top: 80px;
            padding-bottom: 30px;
            min-height: 230px;
            margin-bottom: 25px;
        }

        h2 {
            margin-bottom: 6px;
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .top-info {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #002554;
        margin-bottom: 79px;
        @include breakpoint-down(md){
            margin-bottom: 25px;
        }

        .subtitle {
            font-weight: 600;
            margin: 18px 0 22px;
        }

        .desc {
            width: 932px;
            margin: 0 auto;
            @include breakpoint-down(md){
                width: 100%;
            }
        }
    }

    .img-info-wrap {
        margin-bottom: 60px;
        @include breakpoint-down(md){
            margin-bottom: 0;
        }
    }

    .img-info-item {
        display: flex;
        @include breakpoint-down(md){
            display: block;
        }

        &.revers {
            flex-direction: row-reverse;

            .right {
                padding-right: 59px;
                padding-left: 0;
                @include breakpoint-down(md){
                    padding-right: 0;
                }
            }
        }

        .left {
            width: 50%;
            @include breakpoint-down(md){
                width: 100%;
            }
        }

        .right {
            width: 50%;
            padding-top: 34px;
            padding-left: 59px;
            @include breakpoint-down(md){
                width: 100%;
                padding-left: 0;
            }
        }

        .img {
            @include breakpoint-down(md){
                height: 300px;
            }
            img {
                max-width: 100%;
                height: 100%;
            }
        }

        h2 {
            margin-bottom: 24px;
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #002554;

            .red {
                text-decoration-line: underline;
                color: #D50032;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: flex-start;

                    .img {
                        margin-right: 26px;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

    .attention {
        width: 740px;
        margin: 0 auto 27px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #002554;
        padding: 40px 0;
        @include breakpoint-down(md){
            width: 100%;
            padding: 20px 0;
        }

        .red {
            color: #D50032;
        }
    }
}

.prices {
    width: 952px;
    margin: 0 auto 64px;
    display: flex;
    @include breakpoint-down(md){
        display: block;
        width: 100%;
    }

    .right{
        display: flex;
    }

    .free-block {
        width: 299px;
        background: #FFFFFF;
        box-shadow: 0 5px 130px rgba(0, 0, 0, 0.07);
        @include breakpoint-down(md){
            width: 100%;
            margin-bottom: 30px;
        }

        .desc {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #3A3A44;
            background: #F5F5F5;
            padding: 37px 10px;
            margin-bottom: 33px;
        }
    }

    .prices-block-item {
        padding: 18px 13px;
        background: #FFFFFF;
        box-shadow: 0 5px 130px rgba(0, 0, 0, 0.07);
    }

    .price {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #D50032;
        margin: 13px 0 16px;
    }

    .months {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #3A3A44;
    }

    .buy {
        width: 230px;
        height: 43px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        justify-content: center;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        background: #002554;
        border-radius: 5px;
        margin: 0 auto;
        position: relative;
        z-index: 5;
    }

    .right{
        padding-left: 28px;
        @include breakpoint-down(md){
            padding-left: 0;
        }

        .prices-block-wrap{
            display: flex;
            flex-wrap: wrap;
            margin: -18px -14px;
        }

        .prices-block-item-wrap{
            width: 299px;
            padding: 18px 14px;
            @include breakpoint-down(md){
                width: 100%;
                padding: 10px 14px;
            }
        }

        .price{
            position: relative;
            span{
                position: relative;
                z-index: 5;
            }

            &::after{
                content: '';
                position: absolute;
                background: #F5F5F5;
                left: 0;
                bottom: -40px;
                top: -10px;
                right: 0;
            }
        }
    }
}
