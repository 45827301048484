.nav-main {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    @include breakpoint-down(md) {
        position: fixed;
        left: 0;
        top: 60px;
        bottom: 0;
        overflow: auto;
        background-color: #002554;
        padding: 50px 40px 20px;
        border-top: solid 1px #D50032;
        transform: translate(-100%, 0px);
        opacity: 0;
        transition: opacity .3s ease-in-out, transform .2s ease-in;

        .list-inline {
            flex-direction: column;
        }
    }

    .list-inline {
        flex-wrap: wrap;
    }

    li {
        margin-right: 35px;
        padding: 4px 0;

        &:last-child {
            margin-right: 0;
        }

        &.selected {
            font-weight: bold;
        }

        @include breakpoint-down(md) {
            margin-bottom: 15px;
        }
    }

    a {
        &:hover {
            color: #fff;
        }
    }
}

.show-menu{
    .nav-main{
        transform: translate(0);
        opacity: 1;
    }
}

.footer__nav {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    list-style: none;
    padding: 0;
    margin: 0 34px 0 0;

    &:last-child {
        margin: 0;
    }

    &-wrap {
        display: flex;
    }

    li {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.dropdown-menu {
    margin: 0;
    border-radius: 0;
    border: none;
    @include breakpoint-down(md) {
        position: relative;
        background-color: transparent;
    }
}

.mydropdownjQuery {
    position: relative;
}

.dropdown-item {
    padding: 0 14px;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    transition: color .2s ease-in-out;
    color: #002554;
    @include breakpoint-down(md) {
        color: #fff;
        font-size: 14px;
    }

    &:last-child {
        margin-bottom: 8px;
    }

    &:hover {
        color: #D50032 !important;
        background-color: transparent;
    }
}

.dropdown-link {
    display: flex;

    .icon {
        margin-left: 4px;
        width: 10px;
        height: 8px;
        @include breakpoint-down(md) {
            display: none;
        }
    }
}
